@import "../../../shared/styles/settings.scss";

.file {
	border: 1px dashed $main-blue;
	border-radius: 10px;
	background: rgba(27, 170, 240, 0.05);
	width: 330px;
	height: 55px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;
	transition: $transition;

	&:not(.no-empty) {
		cursor: pointer;
	}

	&.no-empty {
		background: #fff;
		border: 1px solid $main-blue;
	}

	&__uploaded {
		cursor: pointer;
		&-name {
			width: 100%;
		}
	}

	&__edit {
		margin-right: 8px;
	}

	&__edit,
	&__delete {
		width: 24px;
		height: 24px;
	}

	input[type="file"] {
		display: none;
	}
}
