@import "./settings.scss";
@import "./reset.scss";
@import "./animation.scss";
@import "./icons.scss";

body {
	font-size: 16px;
	font-family: "Roboto";
	color: $black;
	min-width: 720px;
	line-height: 16px;
}

.controller {
	user-select: none;
}

[class*="-icon"] {
	transition: $transition;
	background-color: transparent;
	&:hover {
		box-shadow: $shadow;
	}
}

.hidden {
	display: none;
}

.color-grey {
	color: $grey;
}

.color-blue {
	color: $main-blue;
}

.link {
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	&-blue {
		color: $main-blue;
	}

	&-white {
		color: #fff;
	}

	&-grey {
		color: $light-grey;
	}

	&-disabled {
		color: $grey;

		&:hover {
			text-decoration: none;
		}
	}
}

.title {
	font-size: 24px;
	line-height: 28px;
	font-weight: 500;
}

.sub-title {
	color: $grey;
	font-size: 16px;
}

.active {
	color: $main-blue;
	cursor: default;
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

.text-align-left {
	text-align: left;
}

.pos-rel {
	position: relative;
}

.flex {
	display: flex;
}

.flex-between-center {
	@include flex-between-center;
}

.flex-column-center {
	@include flex-column-center;
}

.flex-between {
	@include flex-between;
}

// Grid

.container {
	width: 100%;
	padding: 0 24px;
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -24px;
	padding: 0 24px;
}

.col-1 {
	width: 1 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-2 {
	width: 2 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-3 {
	width: 3 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-4 {
	width: 4 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-5 {
	width: 5 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-6 {
	width: 6 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-7 {
	width: 7 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-8 {
	width: 8 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-9 {
	width: 9 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-10 {
	width: 10 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-11 {
	width: 11 / 12 * 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.col-12 {
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
}
