@import "../../../shared/styles/settings.scss";

.projects-rm {
	&__title {
		margin-bottom: 32px;
	}

	&__table {
		height: calc(100vh - 400px);
		overflow-y: auto;
		overflow-x: hidden;

		&-head {
			position: sticky;
			top: 0;
			z-index: 9999;
			background-color: #fff;
		}
	}

	&__add {
		width: 100%;
		padding: 20px 0;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: $border;
		& div:first-child {
			cursor: pointer;
		}
		[class*="-icon"] {
			height: 24px;
			width: 24px;
			margin-right: 12px;
		}
	}

	&__footer {
		box-shadow: $shadow;
		width: 100%;
		background-color: #fff;
		position: fixed;
		bottom: 0;
		left: 0;
	}

	&__item {
		&:hover &-delete {
			opacity: 1;
		}

		&-delete {
			opacity: 0;
			width: 24px;
			height: 24px;
			position: absolute;
			right: 48px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__options {
		margin: 32px -24px 20px -24px !important;
		padding: 32px 8px 32px 8px !important;
		border-top: $border;
		border-bottom: $border;

		[class*="col-"] {
			padding: 0 16px;

			&:last-child {
				margin: 0 auto;
			}
		}

		&-price {
			display: flex;
			align-items: center;
			padding: 2px 8px;
			height: 40px;
			border-radius: 6px;
			border: $border;
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	&__submit {
		padding: 20px 0;
		margin-bottom: 20px !important;

		&-btn {
			width: 100%;
			max-width: 400px;
			margin: 0 auto;
		}
	}
}

.table-input {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
