@import "../../styles/settings.scss";

.btn {
	padding: 12px;
	background-color: $main-blue;
	border: 2px solid transparent;
	border-radius: 24px;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0.0125em;
	color: #fff;
	cursor: pointer;
	transition: $transition;
	text-transform: uppercase;

	&.full-width {
		width: 100%;
	}

	&:hover {
		box-shadow: $shadow;
	}

	&.disabled {
		background-color: #e1e1e1;
		color: #fff;
		cursor: not-allowed;
		&:hover {
			box-shadow: none;
		}
	}
}
