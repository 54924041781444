@import "../../shared/styles/settings.scss";

.header {
	height: 85px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px;
	border-bottom: $border;
	margin-bottom: 24px;

	&__project {
		&-name {
			margin-left: auto;
			span {
				color: $grey;
			}
		}
	}

	[class*="-icon"] {
		width: 32px;
		height: 32px;
	}

	.avatar {
		margin-left: auto;
	}
}
