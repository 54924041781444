.close-icon {
	border-radius: 50%;
	display: block;
	cursor: pointer;
	background-image: url(/images/svg/close.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.add-icon {
	border-radius: 50%;
	display: block;
	cursor: pointer;
	background-image: url(/images/svg/add.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.additional-icon {
	border-radius: 50%;
	display: block;
	cursor: pointer;
	background-image: url(/images/svg/additional.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.arrow-icon {
	border-radius: 50%;
	display: block;
	cursor: pointer;
	background-image: url(/images/svg/arrow.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.basket-icon {
	border-radius: 50%;
	display: block;
	cursor: pointer;
	background-image: url(/images/svg/basket.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.edit-icon {
	border-radius: 50%;
	display: block;
	cursor: pointer;
	background-image: url(/images/svg/edit.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.notification-icon {
	border-radius: 50%;
	display: block;
	cursor: pointer;
	background-image: url(/images/svg/notification.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.search-icon {
	display: block;
	cursor: pointer;
	background-image: url(/images/svg/search.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
