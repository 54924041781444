@import "../../../shared/styles/settings.scss";

.projects {
	&-actions {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 24px;
	}

	&__search {
		margin-right: 24px;
		position: relative;

		&-btn {
			position: absolute;
			width: 18px;
			height: 18px;
			top: 50%;
			left: 20px;
			transform: translateY(-50%);
		}

		&-input {
			border: $border;
			background-color: #f2f2f2;
			border-radius: 8px;
			padding: 12px 8px 12px 48px;
			transition: $transition;

			&:focus {
				border: 1px solid $main-blue;
			}
		}
	}

	&-table {
		&__head {
			&-item {
				padding: 12px 8px;
				color: $grey;
				font-size: 12px;
				border-top: $border;
				border-bottom: $border;
				border-right: $border;
				&:last-child {
					border-right: none;
				}
			}
		}

		&__body {
			&-row {
				transition: $transition;
				position: relative;
				&:hover {
					cursor: pointer;
					color: $main-blue;
				}
			}

			&-item {
				padding: 24px 8px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				border-bottom: $border;
				border-right: $border;
				&:last-child {
					border-right: none;
				}
			}
		}
	}
}
