@import "../../../shared/styles/settings.scss";

.project-edit {
	border-bottom: $border;
	&__head {
		margin-bottom: 24px;
		display: flex;
		justify-content: space-between;

		&-actions {
			display: flex;

			[class*="-icon"] {
				width: 32px;
				height: 32px;

				&:first-child {
					margin-right: 24px;
				}
			}
		}
	}

	&__delete {
		display: flex;
		flex-direction: column;
		padding: 10px;
		&-actions {
			margin-top: 15px;
			@include flex-between-center;
		}
	}

	&__body {
		padding-bottom: 8px;
		border-bottom: $border;
		&-item {
			margin-bottom: 24px;

			.sub-title {
				margin-bottom: 8px;
			}
		}
	}

	&__files {
		flex-wrap: wrap;
		margin-bottom: -20px;
		.file {
			margin-bottom: 20px;
		}
	}

	&__rm {
		margin-top: 32px;
		padding-bottom: 32px;

		&-head {
			margin-bottom: 32px;
		}
	}
}
