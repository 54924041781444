@import "../../../shared/styles/settings.scss";
.avatar {
	width: 40px;
	height: 40px;
	cursor: pointer;
	position: relative;

	&__img {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}

	&__menu {
		&-item {
			transition: $transition;
			padding: 5px 10px;
			margin: 0 -10px;

			&:hover {
				background-color: #dedede;
			}
		}
	}
}

.popup {
	position: absolute;
	min-width: 250px;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: $shadow;
	padding: 10px;
	right: 0;
	z-index: 9999;
}
