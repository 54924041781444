@import "../../../shared/styles/settings.scss";

.loader {
	width: 94px;
	height: 94px;
	background-image: url("/images/svg/loader.svg");
	background-repeat: no-repeat;
	background-size: contain;
	margin: 70px auto;
	animation: loading 1.5s infinite linear;
}

@keyframes loading {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
