@import "../../../shared/styles/settings.scss";

.text-input {
	&__label {
		background: #f5f5f5;
		border-radius: 12px;
		display: block;
		position: relative;
		min-height: 64px;
		padding: 10px 16px;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		cursor: text;
	}

	&__field {
		width: 100%;
		margin-top: 18px;
		height: 20px;
		resize: none;
		overflow: hidden;
	}

	&__field:focus ~ &__placeholder,
	&__field:valid ~ &__placeholder {
		top: 10px;
		left: 16px;
		font-size: 14px;
		line-height: 14px;
		margin: 10px 0;
	}

	&__placeholder {
		color: $grey-text;
		position: absolute;
		pointer-events: none;
		left: 16px;
		top: 50%;
		transform: translateY(-50%);
		transition: all 0.15s ease;
	}
}
