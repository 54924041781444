@import "../../../shared/styles/settings.scss";

.modal {
	padding: 32px 24px;
	width: 450px;
	max-height: 700px;
	border-radius: 16px;
	border: $border;
	position: absolute;
	top: calc(50% - 85px);
	background-color: #fff;
	left: 50%;
	transform: translate(-50%, -50%);

	&-blur {
		position: absolute;
		top: 85px;
		left: 0;
		height: calc(100% - 85px);
		width: 100%;
		background: rgba(255, 255, 255, 0.9);
		backdrop-filter: blur(4px);
		z-index: 9999;
	}

	&__head {
		@include flex-between-center;
		margin-bottom: 32px;

		&-close-btn {
			width: 32px;
			height: 32px;
		}
	}

	&__content {
		overflow-y: auto;
		margin-bottom: 32px;
	}

	.text-input__label {
		margin-bottom: 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
