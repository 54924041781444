$main-blue: #1baaf0;
$black: rgba(0, 0, 0, 0.87);
$light-grey: rgba(0, 0, 0, 0.45);
$grey: #828282;
$grey-text: #bdbdbd;
$border: 1px solid #e0e0e0;
$shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
$margin: 8px;
$transition: all 0.15s ease-in-out;

@mixin flex-between {
	display: flex;
	justify-content: space-between;
}

@mixin text-overflow-hidden {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

@mixin flex-between-center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin flex-column-center {
	display: flex;
	flex-direction: column;
	align-items: center;
}
